import { Controller } from "stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["container", "overlay", "dialog", "body", "spinner"];

  open() {
    this.containerTarget.classList.remove('hidden');
    enter(this.overlayTarget);
    enter(this.dialogTarget);
  }

  openRemote(event) {
    event.preventDefault();

    const url = event.currentTarget.href;

    fetch(url)
      .then(response => response.text())
      .then((text) => {
        this.bodyTarget.innerHTML = text;
        this.spinnerTarget.classList.add('hidden'); })
      .catch(error => console.log(error));

    this.open();
  }

  close() {
    Promise.all([leave(this.overlayTarget), leave(this.dialogTarget)]).then(() => {
      this.bodyTarget.innerHTML = "";
      this.spinnerTarget.classList.remove('hidden');
      this.containerTarget.classList.add('hidden');
    });
  }
}
