import { Controller } from "stimulus"
import Toastify from "toastify-js"

export default class extends Controller {
  showAlertToast(event) {
    Toastify({
      text: event.detail.text,
      duration: 5000,
      gravity: "top",
      position: "right",
      stopOnFocus: true,
    }).showToast();
  }
}
