import { Controller } from "stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = [ "menu", "openIcon", "closeIcon" ]

  initialize() {
    this.menuOpen = false;
  }

  toggle() {
    this.menuOpen ? this.hideMenu() : this.showMenu();
    this.menuOpen = !this.menuOpen;
  }

  showMenu() {
    if (this.hasOpenIconTarget) {
      this.openIconTarget.classList.replace("block", "hidden");
    }

    if (this.hasCloseIconTarget) {
      this.closeIconTarget.classList.replace("hidden", "block");
    }

    this.menuTargets.forEach((target) => {
      target.toggleAttribute('hidden')
      enter(target);
    });
  }

  hideMenu() {
    if (this.hasOpenIconTarget) {
      this.openIconTarget.classList.replace("hidden", "block");
    }

    if (this.hasCloseIconTarget) {
      this.closeIconTarget.classList.replace("block", "hidden");
    }

    this.menuTargets.forEach((target) => {
      target.toggleAttribute('hidden')
      leave(target)
    });
  }
}
