import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

const images = import.meta.glob("../images/**/*", { eager: true })

import "../channels"
import "../controllers"
import "../application.sass"
import "toastify-js/src/toastify.css"
