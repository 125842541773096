import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["select", "form"];

  submitForm(event) {
    this.formTarget.requestSubmit();
  }
}

