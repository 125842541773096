import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["row"];

  updateRow(event) {
    let [data, status, xhr] = event.detail;

    if (data?.alert) {
      this.triggerToast(event.target, data.alert.text);
      event.target.closest("[data-index-target=row]").outerHTML = data.rowHtml;
    }

    else {
      let newHtml = xhr.response;
      event.target.closest("[data-index-target=row]").outerHTML = newHtml;
    }
  }

  removeRow(event) {
    let [data, status, xhr] = event.detail;

    if (data?.alert) {
      this.triggerToast(event.target, data.alert.text);

      if (data.result === "success") {
        event.target.closest("[data-index-target=row]").remove();
      }
    }

    else {
      event.target.closest("[data-index-target=row]").remove();
    }
  }

  triggerToast(element, text) {
    let toastEvent = new CustomEvent('toast-alert', {
      bubbles: true,
      detail: { text: text },
    });

    element.dispatchEvent(toastEvent);
  }
}
